<template>
	<base-col col="12">
		<div class="advantages">
			<div class="product-content">
				<base-font class="header" variant="medium-header" tag="h2" size="lg" marginReset>{{ value.heading }}</base-font>
				<ul class="product-list ">
					<li class="product-list-item" v-for="(item) in value.list" :key="item.title">
						<div class="list-circle"></div>
						<div class="text">
							<base-font class="title" variant="medium-header" tag="h2">{{item.title}}</base-font>
							<span class="separator" v-if="item.description"></span>
							<base-font class="description" variant="paragraph" :html="item.description" uppercase />
						</div>
					</li>
				</ul>
			</div>
		</div>
	</base-col>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.advantages {
	position: relative;
	&::before {
		height: 100%;
		content: "";
		width: 100vw;
		position: absolute;
		margin-left: -50vw;
		left: 50%;
		top: 0;
		background: $white;
	}
}
.product-content {
	padding-top: 4rem;
	padding-bottom: 4rem;
	@include media-breakpoint-up(lg){
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
	:deep(.header) {
		text-align: center;
		width: 100%;
	}
	.product-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		padding: 0;
		margin-top: 4rem;
		row-gap: 3rem;
		@include media-breakpoint-up(xxl){
			margin-top: 8rem;
			row-gap: 6rem;
		}
		&-item {
			flex: 0 1 100%;
			display: flex;
			align-items: flex-start;
			@include media-breakpoint-up(lg) {
				flex: 0 1 48%;
			}
			@include media-breakpoint-up(xl) {
				flex: 0 1 33%;
			}

			&:nth-child(odd):last-of-type {
				flex-grow: 1;
			}

			.text {
				position: absolute;
				left: 50px;
				align-self: center;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@include media-breakpoint-up(lg) {
					width: 40%;
					margin-left: 50px;
					left: auto;
				}

				@include media-breakpoint-up(xl) {
					width: 27%;

					:deep(p) {
						line-height: 2.4rem;
					}
				}

				&:before {
					content: '';
					background: $white;
					height: 120px;
					width: 100%;
					position: absolute;
					z-index: 2;

					@include media-breakpoint-up(xl) {
						min-height: 100px;
						height: 140px;
					}
					@include media-breakpoint-up(xxl) {
						min-height: 100px;
						height: 120px;
					}
				}

				.separator {
					&::after {
						content: '';
						flex-grow: 1;
						height: 2px;
						background-color: #EAEAEA;
						width: 50px;
						position: absolute;
						top: 30px;
						z-index: 2;

						@include media-breakpoint-up(xl) {
							top: 35px;
						}
					}
				}

				:deep(.title) {
					width: max-content;
					position: relative;
					z-index: 2;
					display: flex;
					align-items: center;
					margin: 0;
				}
				:deep(.description) {
					position: relative;
					z-index: 2;
					margin-top: 4px;
					letter-spacing: 0.72px;
					font-size: 12px;
				}
			}
		}
	}
	.list-circle {
		width: 150px;
		height: 150px;
		border: 1px solid $gold;
		border-radius: 50%;
		margin-right: 2rem;
		z-index: 1;
	}
}
</style>
